import * as React from "react"
import Typography from "@mui/material/Typography"
import MuiLink from "@mui/material/Link"
import Grid from "@mui/material/Unstable_Grid2"
import {StaticImage} from "gatsby-plugin-image"

interface FooterProps {
  copyrightOwnership?: string
}

const Footer: React.FC<FooterProps> = ({copyrightOwnership}) => {
  return (
    <Grid container spacing={0} rowSpacing={0} columns={3} marginBottom={2}>
      <Grid xs={3} md={1} zIndex={(theme) => theme.zIndex.drawer + 1} display={"flex"} justifyContent={"center"}>
        <Typography variant="body2" color="text.secondary" mt={3} display={"inline-block"}>
          {"Made by "}
        </Typography>
        <MuiLink color="inherit" href="https://mnem.io/" marginLeft={0.5} marginTop={1.75}>
          <StaticImage
            src={"../images/mnemio-banner.svg"}
            alt={"MNEMIO"}
            layout="fixed"
            height={30}
            style={{display: "inline-block", marginBottom: -4}}
          />
        </MuiLink>
      </Grid>
      <Grid xs={3} md={1} zIndex={(theme) => theme.zIndex.drawer + 1} padding={0}>
        <Typography variant="body2" color="text.secondary" align="center" mt={3}>
          {copyrightOwnership ? `Copyright © ${copyrightOwnership}` : ""}
        </Typography>
      </Grid>
      <Grid xs={3} md={1} zIndex={(theme) => theme.zIndex.drawer + 1} padding={0} display={"flex"}
            justifyContent={"center"}>
        <Typography variant="body2" color="text.secondary" mt={3} display={"inline-block"}>
          {"Powered by "}
        </Typography>
        <MuiLink color="inherit" href="https://mnemicon.com/" marginLeft={0.5} marginTop={1.5}>
          <StaticImage
            src={"../images/mnemicon-raven-banner.svg"}
            alt={"MNEMICON"}
            layout="fixed"
            height={30}
            style={{display: "inline-block", marginBottom: -4}}
          />
        </MuiLink>
      </Grid>
    </Grid>
  )
}

export default Footer
